import BaseComponent from './base-component'
import SelectorEngine from './dom/selector-engine'
import * as Mustache from 'mustache'

const NAME = 'vacancy'
const SELECTOR = '.js-vacancy'
const BTN_SELECTOR = '.js-vacancy-btn'
const POPUP_SELECTOR = '.js-vacancy-popup'
const CLOSE_POPUP_SELECTOR = '.js-vacancy-popup-close'
const BACKGROUND_POPUP_SELECTOR = '.js-vacancy-popup-background'
const FILE_UPLOAD_SELECTOR = '.js-vacancy-form-upload'
const FILE_UPLOAD_TEMPLATE_SELECTOR = '.js-vacancy-uploaded-template'
const FILE_UPLOAD_CONTAINER_SELECTOR = '.js-vacancy-form-uploaded-files'

const POPUP_ACTIVE_CLASS = 'vacancy__popup--active'

const vacancyMustache = Mustache.default

export default class Vacancy extends BaseComponent {
  constructor(element) {
    super(element)
    this.initVacancyPage()
  }

  initVacancyPage() {
    const vacancy = this._element
    const openPopupBtn = vacancy.querySelector(BTN_SELECTOR)
    const closePopupBtn = vacancy.querySelector(CLOSE_POPUP_SELECTOR)
    const popup = vacancy.querySelector(POPUP_SELECTOR)
    const backgroundPopup = vacancy.querySelector(BACKGROUND_POPUP_SELECTOR)
    const uploadInput = vacancy.querySelector(FILE_UPLOAD_SELECTOR)

    openPopupBtn.addEventListener('click', () => this.showPopup(popup))
    closePopupBtn.addEventListener('click', () => this.hidePopup(popup))
    backgroundPopup.addEventListener('click', () => this.hidePopup(popup))
    uploadInput.addEventListener('change', () => this.showUploadedFile(uploadInput))
  }

  showPopup(popup) {
    popup.classList.add(POPUP_ACTIVE_CLASS)
  }

  hidePopup(popup) {
    popup.classList.remove(POPUP_ACTIVE_CLASS)
  }

  showUploadedFile(uploadInput) {
    const container = uploadInput.parentNode.querySelector(FILE_UPLOAD_CONTAINER_SELECTOR)
    const template = document.querySelector(FILE_UPLOAD_TEMPLATE_SELECTOR).innerHTML

    const params = this.getFileInfo(uploadInput.files[0])

    container.innerHTML = this.renderFileResult(template, params)
  }

  getFileInfo(file) {
    const fileName = file.name
    const size = Math.round(10 * file.size / 1024) / 10
    const extension = fileName?.split('.')?.pop()

    return {
      fileName,
      size,
      extension
    }
  }

  renderFileResult(template, params) {
    return vacancyMustache.render(template, params)
  }

  static vacancyInterface(element, config) {
    const data = Vacancy.getOrCreateInstance(element, config)

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static get NAME() {
    return NAME
  }
}

const selectorElements = SelectorEngine.find(SELECTOR)

selectorElements.forEach(element => {
  Vacancy.vacancyInterface(element)
})
