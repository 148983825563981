import BaseComponent from './base-component'
import SelectorEngine from './dom/selector-engine'
import { Breakpoints, defineJQueryPlugin } from './util/index'

const FULL_STOCK_PIN = '/docs/5.0/assets/img/evraz/full-pin.svg'
const NAME = 'delivery-map'

const SELECTOR = '.js-delivery-map'

export default class DeliveryMap extends BaseComponent {
  constructor(element) {
    super(element)
    // eslint-disable-next-line no-undef
    ymaps.ready(() => {
      // this.initMap()
      this.init()
    })
  }

  init() {
    this.myPlacemark = null
    // eslint-disable-next-line no-undef
    this.myMap = new ymaps.Map('delivery-map', {
      center: [55.76, 37.64],
      zoom: 10
    }, { provider: 'yandex#map' }
    )

    this.myMap.events.add('click', e => {
      const coords = e.get('coords')

      if (this.myPlacemark) {
        this.myPlacemark.geometry.setCoordinates(coords)
      } else {
        this.myPlacemark = this.createPlacemark(coords)
        this.myMap.geoObjects.add(this.myPlacemark)

        this.myPlacemark.events.add('dragend', () => {
          this.getAddress(this.myPlacemark.geometry.getCoordinates())
        })
      }

      this.getAddress(coords)
    })
  }

  getAddress(coords) {
    this.myPlacemark.properties.set('iconCaption', 'поиск...')
    // eslint-disable-next-line no-undef
    ymaps.geocode(coords).then(res => {
      const firstGeoObject = res.geoObjects.get(0)

      this.myPlacemark.properties
        .set({
          iconCaption: [
            firstGeoObject.getLocalities().length ? firstGeoObject.getLocalities() : firstGeoObject.getAdministrativeAreas(),
            firstGeoObject.getThoroughfare() || firstGeoObject.getPremise()
          ].filter(Boolean).join(', '),
          balloonContent: `<div class="pin-address">
                                <strong class="color-black d-block pb-2"><i class="icn-location"></i> ${firstGeoObject.getAddressLine()}</strong>
                                <button type="button" class="btn btn-primary mb-2 w-100">Добавить сюда</button>
                            </div>`
        })
    })
  }

  createPlacemark(coords) {
    // eslint-disable-next-line no-undef
    return new ymaps.Placemark(coords, {
      iconCaption: 'поиск...'
    }, {
      preset: 'islands#violetDotIconWithCaption',
      draggable: true,
      iconLayout: 'default#image',
      iconImageHref: FULL_STOCK_PIN,
      iconImageSize: [40, 40],
      balloonPanelMaxMapArea: window.innerWidth < Breakpoints.md ? 'Infinity' : '500px'
    })
  }

  static deliveryMapInterface(element, config) {
    const data = DeliveryMap.getOrCreateInstance(element, config)

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static get NAME() {
    return NAME
  }
}

const selectorElements = SelectorEngine.find(SELECTOR)
selectorElements.forEach(element => {
  DeliveryMap.deliveryMapInterface(element)
})

defineJQueryPlugin(DeliveryMap)

