import SelectorEngine from './dom/selector-engine'
import BaseComponent from './base-component'

const NAME = 'order'
const SELECTOR = '.js-order'
const ORDER_COMPOSITION_BTN_SELECTOR = '.js-order-composition-header-btn'
const PAYMENT_METHODS_CLOSE_SELECTOR = '.js-payment-methods-close'
const PAYMENT_METHODS_ACCEPT_SELECTOR = '.js-payment-methods-accept'
const LOAD_PROXY_BTN_SELECTOR = '.js-load-proxy'
const APPEAL_BTN_SELECTOR = '.js-appeal-btn'
const REPEAT_ORDER_CLOSE_SELECTOR = '.js-repeat-order-close'
const REPEAT_ORDER_ACCEPT_SELECTOR = '.js-repeat-order-accept'
const CANCEL_ORDER_CLOSE_SELECTOR = '.js-cancel-order-close'
const CANCEL_ORDER_ACCEPT_SELECTOR = '.js-cancel-order-accept'

class Order extends BaseComponent {
  constructor(element) {
    super(element)
    this.initOrder()
  }

  initOrder() {
    const order = this._element
    const orderCompositionBtn = order.querySelector(ORDER_COMPOSITION_BTN_SELECTOR)
    const paymentMethodsClose = order.querySelector(PAYMENT_METHODS_CLOSE_SELECTOR)
    const paymentMethodsAccept = order.querySelector(PAYMENT_METHODS_ACCEPT_SELECTOR)
    const loadProxyBtn = order.querySelector(LOAD_PROXY_BTN_SELECTOR)
    const appealBtn = order.querySelector(APPEAL_BTN_SELECTOR)
    const repeatOrderClose = order.querySelector(REPEAT_ORDER_CLOSE_SELECTOR)
    const repeatOrderAccept = order.querySelector(REPEAT_ORDER_ACCEPT_SELECTOR)
    const cancelOrderClose = order.querySelector(CANCEL_ORDER_CLOSE_SELECTOR)
    const cancelOrderAccept = order.querySelector(CANCEL_ORDER_ACCEPT_SELECTOR)

    cancelOrderClose.addEventListener('click', this.closePopupHandler)
    repeatOrderClose.addEventListener('click', this.closePopupHandler)
    paymentMethodsClose.addEventListener('click', this.closePopupHandler)

    orderCompositionBtn.addEventListener('click', this.doSomeThing)
    paymentMethodsAccept.addEventListener('click', this.doSomeThing)
    loadProxyBtn.addEventListener('click', this.doSomeThing)
    appealBtn.addEventListener('click', this.doSomeThing)
    repeatOrderAccept.addEventListener('click', this.doSomeThing)
    cancelOrderAccept.addEventListener('click', this.doSomeThing)
  }

  closePopupHandler() {}

  doSomeThing() {}

  static orederInterface(element, config) {
    const data = Order.getOrCreateInstance(element, config)

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static get NAME() {
    return NAME
  }
}

const selectorElements = SelectorEngine.find(SELECTOR)
selectorElements.forEach(element => {
  Order.orederInterface(element)
})

export default Order
