import BaseComponent from './base-component'
import EventHandler from './dom/event-handler'
import SelectorEngine from './dom/selector-engine'
import { defineJQueryPlugin } from './util/index'

const NAME = 'mobileMenu'

const SELECTOR = '.js-mobile-menu'
const SELECTOR_ITEM = '.js-mobile-menu-item'
const SELECTOR_BACK = '.js-mobile-menu-item-back'

const CLASS_CONTROL_VISIBLE = 'd-block'

export default class MobileMenu extends BaseComponent {
  constructor(element) {
    super(element)
    this.init()
  }

  init() {
    if (!this._element) {
      return
    }

    this.items = SelectorEngine.find(SELECTOR_ITEM, this._element)
    this.back = SelectorEngine.find(SELECTOR_BACK, this._element)
    this.catalogModal = document.getElementById('catalogModal')

    this.items.forEach(element => {
      EventHandler.on(element, 'click', () => {
        const subMenuItem = SelectorEngine.findOne(SELECTOR_ITEM, element)

        if (subMenuItem) {
          subMenuItem.classList.add(CLASS_CONTROL_VISIBLE)
        }
      })
    })

    this.back.forEach(element => {
      EventHandler.on(element, 'click', e => {
        e.stopPropagation()

        const subMenuItem = SelectorEngine.parents(element, SELECTOR_ITEM)

        if (subMenuItem.length > 0) {
          subMenuItem[0].classList.remove(CLASS_CONTROL_VISIBLE)
        }
      })
    })

    this.catalogModal.addEventListener('hide.bs.modal', () => {
      this.items.forEach(element => {
        element.classList.remove(CLASS_CONTROL_VISIBLE)
      })
    })
  }

  static mobileMenuInterface(element, config) {
    const data = MobileMenu.getOrCreateInstance(element, config)

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static get NAME() {
    return NAME
  }
}

const selectorElements = SelectorEngine.find(SELECTOR)
selectorElements.forEach(element => {
  MobileMenu.mobileMenuInterface(element)
})

defineJQueryPlugin(MobileMenu)
