import BaseComponent from './base-component'
import SelectorEngine from './dom/selector-engine'

const SELECTOR = '.js-list-picker'
const ELEM_SELECTOR = '.js-list-picker-elem'
const NAME = 'list-picker'

export default class ListPicker extends BaseComponent {
  constructor(element) {
    super(element)
    this.initListPicker()
  }

  initListPicker() {
    const listPicker = this._element
    const elemList = listPicker.querySelectorAll(ELEM_SELECTOR)

    elemList.forEach(elem => {
      elem.addEventListener('click', () => this.setActiveElem(elem))
    })
  }

  setActiveElem(elem) {
    const listPicker = this._element
    const activeClass = listPicker.dataset.active
    const jsActiveClass = listPicker.dataset.js
    const currentElem = listPicker.querySelector(`.${jsActiveClass}`)

    if (currentElem) {
      currentElem.classList.remove(jsActiveClass)
      currentElem.classList.remove(activeClass)
    }

    elem.classList.add(jsActiveClass)
    elem.classList.add(activeClass)
  }

  static ListPickerInterface(element, config) {
    const data = ListPicker.getOrCreateInstance(element, config)

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static get NAME() {
    return NAME
  }
}

const selectorElements = SelectorEngine.find(SELECTOR)

selectorElements.forEach(element => {
  ListPicker.ListPickerInterface(element)
})

