import BaseComponent from './base-component'
import SelectorEngine from './dom/selector-engine'
import * as Mustache from 'mustache'

const NAME = 'gallery'
const SELECTOR = '.js-gallery'
const ITEM = '.js-gallery-item'
const ITEM_IMAGE_SELECTOR = '.js-gallery-item-image'
const POPUP_ITEM = '.js-gallery-popup-item'
const POPUP_ITEM_IMAGE_SELECTOR = '.js-item-popup-image'

const MAIN_IMAGE_SELECTOR_WRAPPER = '.js-gallery-image-wrapper'
const POPUP_MAIN_IMAGE_SELECTOR_WRAPPER = '.js-gallery-popup-image-wrapper'

const ACTIVE_CLASS = 'gallery__item--active'
const POPUP_ACTIVE_CLASS = 'gallery-popup__item-image--active'
const POPUP_ARROW_SELECTOR = '.js-gallery-popup-arrow'
const PAGINATION_SELECTOR = '.js-gallery-popup-pagination'

const mustache = Mustache.default

const BREAKPOINT = 768

export default class Gallery extends BaseComponent {
  constructor(element) {
    super(element)
    this.initGalleryItems()
  }

  renderImage(item, backgroundUrl, wrapperClasses) {
    const { template } = item.dataset

    const rendered = mustache.render(template, {
      galleryImage() {
        return function () {
          return `<div class='${wrapperClasses}' style='background-image:${backgroundUrl}'></div>`
        }
      }
    })
    item.innerHTML = rendered
  }

  renderPagination(pagination, index, count) {
    const { template } = pagination.dataset

    const rendered = mustache.render(template, {
      index,
      count
    })
    pagination.innerHTML = rendered
  }

  renderPopupItems(popupItemsWrap, popupList) {
    const template = popupItemsWrap.innerHTML

    const rendered = mustache.render(template, {
      popupList
    })
    popupItemsWrap.innerHTML = rendered
  }

  initGalleryItems() {
    const gallery = this._element
    const galleryItems = gallery.querySelectorAll(ITEM)
    const itemsCount = galleryItems?.length
    const popup = this.initPopup(gallery)
    const galleryPopupItems = gallery.querySelectorAll(POPUP_ITEM)
    const arrows = gallery.querySelectorAll(POPUP_ARROW_SELECTOR)

    galleryItems.forEach((galleryItem, index) => {
      const currentIndex = index + 1

      galleryItem.dataset.index = currentIndex
      galleryItem.dataset.count = itemsCount

      if (currentIndex === 1) {
        this.setActiveItem(gallery, galleryItem.dataset.index)
      }

      this.initGalleryItem(gallery, galleryItem, ITEM)
    })
    galleryPopupItems.forEach((galleryPopupItem, index) => {
      const currentIndex = index + 1

      galleryPopupItem.dataset.index = currentIndex
      this.initGalleryItem(gallery, galleryPopupItem, POPUP_ITEM)
    })

    arrows.forEach(arrow => {
      arrow.addEventListener('click', () => this.arrowHandler(gallery, Number(arrow.dataset.arrow)))
    })

    gallery.addEventListener('openPopup', () => this.showPopup(popup))
    gallery.querySelector(MAIN_IMAGE_SELECTOR_WRAPPER).addEventListener('click', () => this.showPopup(popup))
  }

  showPopup(popup) {
    popup.classList.add('gallery-popup--active')
  }

  hidePopup(popup) {
    popup.classList.remove('gallery-popup--active')
  }

  arrowHandler(gallery, direction) {
    const activeItem = Array.from(gallery.querySelectorAll(ITEM)).find(galleryItem => galleryItem.classList.contains(ACTIVE_CLASS))
    const newItemIndex = String(Number(activeItem.dataset.index) + direction)
    const isNeedSetNewIndex = newItemIndex > 0 && newItemIndex <= activeItem.dataset.count

    if (isNeedSetNewIndex) {
      this.removeActiveItem(gallery, activeItem.dataset.index)
      this.setActiveItem(gallery, newItemIndex)
    }
  }

  initGalleryItem(gallery, item, POPUP_ITEM) {
    const context = {
      gallery,
      galleryItem: item,
      setActiveItem: this.setActiveItem,
      renderImage: this.renderImage,
      removeActiveItem: this.removeActiveItem,
      renderPagination: this.renderPagination,
      generateOpenPopupEvent: this.generateOpenPopupEvent,
      selector: POPUP_ITEM
    }

    item.addEventListener('click', this.galleryItemHandler.bind(context))
  }

  galleryItemHandler() {
    const currentIndex = this.galleryItem.dataset.index
    const { gallery } = this
    const galleryItems = gallery.querySelectorAll(this.selector)

    galleryItems.forEach(galleryItem => {
      if (galleryItem.dataset.index === currentIndex) {
        this.setActiveItem(gallery, currentIndex)
      } else {
        this.removeActiveItem(gallery, galleryItem.dataset.index)
      }
    })
    this.generateOpenPopupEvent(gallery)
  }

  generateOpenPopupEvent(elem) {
    if (window.innerWidth <= BREAKPOINT) {
      const event = new Event('openPopup')
      elem.dispatchEvent(event)
    }
  }

  setActiveItem(gallery, currentIndex) {
    const galleryItem = Array.from(gallery.querySelectorAll(ITEM)).find(galleryItem => galleryItem.dataset.index === currentIndex)
    const galleryPopupItem = Array.from(gallery.querySelectorAll(POPUP_ITEM_IMAGE_SELECTOR)).find(galleryItem => galleryItem?.dataset.index === currentIndex)
    const newImageSrc = galleryItem.querySelector(ITEM_IMAGE_SELECTOR)?.style?.backgroundImage

    galleryItem.classList.add(ACTIVE_CLASS)
    galleryPopupItem.classList.add(POPUP_ACTIVE_CLASS)
    this.renderPagination(gallery.querySelector(PAGINATION_SELECTOR), galleryItem.dataset.index, galleryItem.dataset.count)
    this.renderImage(gallery.querySelector(MAIN_IMAGE_SELECTOR_WRAPPER), newImageSrc, 'gallery__image')
    this.renderImage(gallery.querySelector(POPUP_MAIN_IMAGE_SELECTOR_WRAPPER), newImageSrc, 'gallery-popup__image')
  }

  removeActiveItem(gallery, currentIndex) {
    const galleryItem = Array.from(gallery.querySelectorAll(ITEM)).find(galleryItem => galleryItem?.dataset.index === currentIndex)
    const galleryPopupItem = Array.from(gallery.querySelectorAll(POPUP_ITEM_IMAGE_SELECTOR)).find(galleryItem => galleryItem?.dataset.index === currentIndex)

    galleryItem.classList.remove(ACTIVE_CLASS)
    galleryPopupItem.classList.remove(POPUP_ACTIVE_CLASS)
  }

  initPopup(gallery) {
    const galleryItems = Array.from(gallery.querySelectorAll(ITEM))
    const popup = gallery.querySelector('.js-gallery-popup')
    const closeBtn = gallery.querySelector('.js-gallery-btn-close')

    closeBtn.addEventListener('click', () => this.hidePopup(popup))
    gallery.querySelector('.js-gallery-popup-wrapper').addEventListener('click', () => this.hidePopup(popup))
    const count = galleryItems.length
    const popupItems = galleryItems.map((galleryItem, index) => {
      const backgroundUrl = galleryItem.querySelector(ITEM_IMAGE_SELECTOR)?.style?.backgroundImage
      const item = {
        galleryImage() {
          return function () {
            return `<div class='js-item-popup-image gallery-popup__item-image'
                         style='background-image:${backgroundUrl}'
                         data-index=${index + 1}
                         data-count=${count}>
                    </div>`
          }
        }
      }

      return item
    })
    this.renderPopupItems(gallery.querySelector('.js-gallery-popup-items'), popupItems)

    return popup
  }

  static galleryInterface(element, config) {
    const data = Gallery.getOrCreateInstance(element, config)

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static get NAME() {
    return NAME
  }
}

const selectorElements = SelectorEngine.find(SELECTOR)

selectorElements.forEach(element => {
  Gallery.galleryInterface(element)
})
