import BaseComponent from './base-component'
import SelectorEngine from './dom/selector-engine'
import { defineJQueryPlugin } from './util/index'
import Manipulator from './dom/manipulator'

const NAME = 'phone'
const SELECTOR = '.js-phone'

const formatCharacters = ['-', '_', '(', ')', '[', ']', ':', '.', ',', '$', '%', '@', ' ', '/']

const maskCharacters = new Set(['A', '9', '*'])

let originalValue = ''

const keys = {
  asterisk: 42,
  zero: 48,
  nine: 57,
  a: 65,
  z: 90,
  backSpace: 8,
  tab: 9,
  delete: 46,
  left: 37,
  right: 39,
  numberPadZero: 96,
  numberPadNine: 105,
  escape: 27,
  v: 86,
  c: 67,
  x: 88
}

const Default = {
  mask: '999 999-99-99',
  placeHolder: '+7',
  prefix: '+7 '
}

export default class Phone extends BaseComponent {
  constructor(element, config) {
    super(element)
    if (!this._element) {
      return
    }

    this._config = this._getConfig(config)
    this._addEventListeners(this._element)

    if (this._element.value.length > 0) {
      this._formatWithMask(element)
    }
  }

  _getCursorPosition() {
    const element = this._element
    let position = 0

    if (document.selection) {
      element.focus()

      const selectRange = document.selection.createRange()

      selectRange.moveStart('character', -element.value.length)

      position = selectRange.text.length
    } else if (element.selectionStart || element.selectionStart === '0') {
      position = element.selectionStart
    }

    return position
  }

  _isValidCharacter(keyCode, maskCharacter) {
    const maskCharacterCode = maskCharacter.charCodeAt(0)

    if (maskCharacterCode === keys.asterisk) {
      return true
    }

    const isNumber = (keyCode >= keys.zero && keyCode <= keys.nine) ||
          (keyCode >= keys.numberPadZero && keyCode <= keys.numberPadNine)

    if (maskCharacterCode === keys.nine && isNumber) {
      return true
    }

    if (maskCharacterCode === keys.a && keyCode >= keys.a && keyCode <= keys.z) {
      return true
    }

    return false
  }

  _setCursorPosition(index) {
    const element = this._element

    if (element !== null) {
      if (element.createTextRange) {
        const range = element.createTextRange()

        range.move('character', index)
        range.select()
      } else if (element.selectionStart) {
        element.focus()
        element.setSelectionRange(index, index)
      } else {
        element.focus()
      }
    }
  }

  _removeCharacterAtIndex(index) {
    const element = this._element

    if (element.value.length > 0) {
      const newElementValue = element.value.slice(0, index) + element.value.slice(index + 1)

      element.value = newElementValue

      if (element.value.length > 0) {
        this._setCursorPosition(index)
      } else {
        element.focus()
      }
    }
  }

  _insertCharacterAtIndex(index, character) {
    const element = this._element
    const newElementValue = element.value.slice(0, index) + character + element.value.slice(index)

    element.value = newElementValue

    if (element.value.length > 0) {
      this._setCursorPosition(index + 1)
    } else {
      element.focus()
    }
  }

  _checkAndInsertMaskCharacters(index) {
    const element = this._element
    const { mask } = this._config
    const { prefix } = this._config

    const isMaskCharacter = formatCharacters.includes(mask[index - prefix.length])
    const maskAlreadyThere = element.value.charAt(index) === mask[index]

    if (isMaskCharacter && !maskAlreadyThere) {
      this._insertCharacterAtIndex(index, mask[index - prefix.length])
    } else {
      return
    }

    index += 1
  }

  _checkAndRemoveMaskCharacters(index, keyCode) {
    const element = this._element
    const { prefix } = this._config

    if (element.value.length > 0) {
      const character = element.value.charAt(index)

      const isMaskCharacter = formatCharacters.includes(character)

      if (!isMaskCharacter || index === 0 || index === element.value.length || index <= prefix.length) {
        return
      }

      this._removeCharacterAtIndex(index)

      if (keyCode === keys.backSpace) {
        index -= 1
      }

      if (keyCode === keys.delete) {
        index += 1
      }
    }
  }

  _onLostFocus(element) {
    const { mask } = this._config
    const { prefix } = this._config

    if (!element.value.length) {
      return
    }

    if (element.value.length !== mask.length + prefix.length) {
      if (element.value.length === prefix.length) {
        element.value = ''
      }

      return
    }

    for (let i = 0; i < element.value; i++) {
      const elementCharacter = element.value.charAt(i)
      const maskCharacter = mask[i]

      if (maskCharacters.has(maskCharacter)) {
        if (elementCharacter === maskCharacter || maskCharacter.charCodeAt(0) === keys.asterisk) {
          continue
        } else {
          element.value = ''

          return
        }
      } else if (maskCharacter.charCodeAt(0) === keys.a) {
        if (elementCharacter.charCodeAt(0) <= keys.a || elementCharacter >= keys.z) {
          element.value = ''

          return
        }
      } else if (maskCharacter.charCodeAt(0) === keys.nine && (elementCharacter.charCodeAt(0) <= keys.zero || elementCharacter >= keys.nine)) {
        element.value = ''

        return
      }
    }
  }

  _onKeyDown(element, event) {
    let key = event.which

    const { mask } = this._config
    const { prefix } = this._config
    const copyCutPasteKeys = [keys.v, keys.c, keys.x].includes(key) && event.ctrlKey
    const movementKeys = [keys.left, keys.right, keys.tab].includes(key)
    const modifierKeys = event.ctrlKey || event.shiftKey

    if (copyCutPasteKeys || movementKeys || modifierKeys) {
      return true
    }

    if (element.selectionStart === 0 && element.selectionEnd === element.value.length) {
      originalValue = element.value

      element.value = prefix
    }

    if (key === keys.escape) {
      if (originalValue !== '') {
        element.value = originalValue
      }

      return true
    }

    if (key === keys.backSpace || key === keys.delete) {
      if (key === keys.backSpace) {
        if (this._getCursorPosition() <= prefix.length) {
          event.preventDefault()

          return false
        }

        this._checkAndRemoveMaskCharacters(this._getCursorPosition() - 1, key)

        this._removeCharacterAtIndex(this._getCursorPosition() - 1)
      }

      if (key === keys.delete) {
        this._checkAndRemoveMaskCharacters(this._getCursorPosition(), key)

        this._removeCharacterAtIndex(this._getCursorPosition())
      }

      event.preventDefault()

      return false
    }

    if (element.value.length - prefix.length === mask.length) {
      event.preventDefault()

      return false
    }

    this._checkAndInsertMaskCharacters(this._getCursorPosition())

    if (this._isValidCharacter(key, mask[this._getCursorPosition() - prefix.length])) {
      if (key >= keys.numberPadZero && key <= keys.numberPadNine) {
        key -= 48
      }

      const character = event.shiftKey ?
        String.fromCharCode(key).toUpperCase() :
        String.fromCharCode(key).toLowerCase()

      this._insertCharacterAtIndex(this._getCursorPosition(), character)

      this._checkAndInsertMaskCharacters(this._getCursorPosition(element))
    }

    event.preventDefault()
  }

  _onPaste(element, event, data) {
    let pastedText = ''

    if (data !== null && data !== '') {
      pastedText = data
    } else if (event !== null && window.clipboardData && window.clipboardData.getData) {
      pastedText = window.clipboardData.getData('text')
    } else if (event !== null && event.clipboardData && event.clipboardData.getData) {
      pastedText = event.clipboardData.getData('text/plain')
    }

    if (pastedText !== null && pastedText !== '') {
      for (let j = 0; j < formatCharacters.length; j++) {
        pastedText.replace(formatCharacters[j], '')
      }

      for (let i = 0; i < pastedText.length; i++) {
        if (formatCharacters.includes(pastedText[i])) {
          continue
        }

        const keyDownEvent = document.createEventObject ? document.createEventObject() : document.createEvent('Events')

        if (keyDownEvent.initEvent) {
          keyDownEvent.initEvent('keydown', true, true)
        }

        // eslint-disable-next-line no-multi-assign
        keyDownEvent.keyCode = keyDownEvent.which = pastedText[i].charCodeAt(0)

        this._onKeyDown(element, keyDownEvent)
      }
    }

    return false
  }

  _formatWithMask(element) {
    const { value } = element

    element.value = ''

    if (value !== null && value !== '') {
      this._onPaste(element, null, value)
    }
  }

  _getConfig(config) {
    config = {
      ...this.constructor.Default,
      ...Manipulator.getDataAttributes(this._element),
      ...config
    }

    return config
  }

  _addEventListeners(element) {
    element.addEventListener('blur', () => {
      if (!element.getAttribute('readonly')) {
        return this._onLostFocus(element)
      }

      return true
    })

    element.addEventListener('keydown', event => {
      if (!element.getAttribute('readonly')) {
        return this._onKeyDown(element, event)
      }

      return true
    })

    element.addEventListener('paste', event => {
      event.preventDefault()

      return false
    })

    element.addEventListener('mouseenter', event => {
      event.target.setAttribute('autocomplete', 'off')
    })
  }

  getFullMaskLength() {
    return this._config.prefix.length + this._config.mask.length
  }

  getPrefixLength() {
    return this._config.prefix.length
  }

  static phoneInterface(element, config) {
    const data = Phone.getOrCreateInstance(element, config)

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static get Default() {
    return Default
  }

  static get NAME() {
    return NAME
  }
}

const selectorElements = SelectorEngine.find(SELECTOR)
selectorElements.forEach(element => {
  Phone.phoneInterface(element)
})

defineJQueryPlugin(Phone)
