import BaseComponent from './base-component'
import EventHandler from './dom/event-handler'
import SelectorEngine from './dom/selector-engine'
import { defineJQueryPlugin } from './util/index'

const NAME = 'counter'

const SELECTOR = '.js-counter'
const SELECTOR_INPUT = '.js-counter-input'
const SELECTOR_PLUS = '.js-counter-plus'
const SELECTOR_MINUS = '.js-counter-minus'

const CLASS_CONTROL_DISABLED = 'disabled'

export default class Counter extends BaseComponent {
  constructor(element) {
    super(element)
    this.init()
  }

  init() {
    if (!this._element) {
      return
    }

    this.inputEl = SelectorEngine.findOne(SELECTOR_INPUT, this._element)
    this.plusEl = SelectorEngine.findOne(SELECTOR_PLUS, this._element)
    this.minusEl = SelectorEngine.findOne(SELECTOR_MINUS, this._element)

    this.minValue = this.inputEl.getAttribute('data-min')
    this.maxValue = this.inputEl.getAttribute('data-max')

    if (this.inputEl.value === this.minValue) {
      this.minusEl.classList.add(CLASS_CONTROL_DISABLED)
    }

    EventHandler.on(this.inputEl, 'blur', () => {
      const input = this.inputEl

      if (input.value === '') {
        input.value = this.minValue
        this.minusEl.classList.add(CLASS_CONTROL_DISABLED)
      }
    })

    EventHandler.on(this.inputEl, 'input', () => {
      const input = this.inputEl
      const value = input.value.replace(/,/g, '.')
      const condition = /^-?\d*[,.]?\d*$/.test(value) &&
            (value === '' || (Number.parseInt(value, 10) <= this.maxValue && Number.parseInt(value, 10) >= this.minValue))

      const hasOldValueProperty = Object.prototype.hasOwnProperty.call(input, 'oldValue')

      if (condition) {
        input.oldValue = input.value
        input.oldSelectionStart = input.selectionStart
        input.oldSelectionEnd = input.selectionEnd
      } else if (hasOldValueProperty) {
        input.value = input.oldValue
        input.setSelectionRange(input.oldSelectionStart, input.oldSelectionEnd)
      } else {
        input.value = this.minValue
      }

      this.minusEl.classList.toggle(CLASS_CONTROL_DISABLED, value === this.minValue)
      this.plusEl.classList.toggle(CLASS_CONTROL_DISABLED, value === this.maxValue)
    })

    EventHandler.on(this.plusEl, 'click', () => {
      const val = this._getValue()
      this.minusEl.classList.remove(CLASS_CONTROL_DISABLED)

      if (val < this.maxValue) {
        const newVal = val + 1

        if (newVal >= this.maxValue) {
          this.inputEl.value = this.maxValue
          this.plusEl.classList.add(CLASS_CONTROL_DISABLED)
        } else {
          this.inputEl.value = newVal
        }
      }
    })

    EventHandler.on(this.minusEl, 'click', () => {
      const val = this._getValue()
      this.plusEl.classList.remove(CLASS_CONTROL_DISABLED)

      if (val > this.minValue) {
        const newVal = val - 1

        if (newVal <= this.minValue) {
          this.inputEl.value = this.minValue
          this.minusEl.classList.add(CLASS_CONTROL_DISABLED)
        } else {
          this.inputEl.value = newVal
        }
      }
    })
  }

  _getValue() {
    const newVal = this.inputEl.value.replace(/,/g, '.')

    return Number.parseFloat(newVal, 10)
  }

  static counterInterface(element, config) {
    const data = Counter.getOrCreateInstance(element, config)

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static get NAME() {
    return NAME
  }
}

const selectorElements = SelectorEngine.find(SELECTOR)
selectorElements.forEach(element => {
  Counter.counterInterface(element)
})

defineJQueryPlugin(Counter)
