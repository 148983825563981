import {
  defineJQueryPlugin,
  getSelectorFromElement
} from './util/index'
import Data from './dom/data'
import EventHandler from './dom/event-handler'
import Manipulator from './dom/manipulator'
import SelectorEngine from './dom/selector-engine'
import Collapse from './collapse'

const DATA_KEY = 'bs.collapse'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const Default = {
  toggle: true,
  parent: ''
}

const EVENT_SHOW = `show${EVENT_KEY}`
const EVENT_SHOWN = `shown${EVENT_KEY}`
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`

const CLASS_NAME_SHOW = 'show'
const CLASS_NAME_COLLAPSE = 'collapse'
const CLASS_NAME_COLLAPSING = 'collapsing'
const CLASS_NAME_COLLAPSED = 'collapsed'
const CLASS_NAME_ACTIVE = 'active'

const SELECTOR_ACTIVES = '.show, .collapsing'
const SELECTOR_DATA_TOGGLE = '[data-bs-toggle="collapse-menu"]'
const SELECTOR_CHILDREN = '[data-bs-children]'

class NavMenu extends Collapse {
  constructor(element, config) {
    super(element, config)

    this._isTransitioning = false
    this._config = this._getConfig(config)
    this._triggerArray = SelectorEngine.find(
      `${SELECTOR_DATA_TOGGLE}[href="#${this._element.id}"],` +
        `${SELECTOR_DATA_TOGGLE}[data-bs-target="#${this._element.id}"]`
    )

    const toggleList = SelectorEngine.find(SELECTOR_DATA_TOGGLE)

    for (let i = 0, len = toggleList.length; i < len; i++) {
      const elem = toggleList[i]
      const selector = getSelectorFromElement(elem)
      const filterElement = SelectorEngine.find(selector)
          .filter(foundElem => foundElem === this._element)

      if (selector !== null && filterElement.length) {
        this._selector = selector
        this._triggerArray.push(elem)
      }
    }

    this._parent = this._config.parent ? this._getParent() : null

    if (!this._config.parent) {
      this._addAriaAndCollapsedClass(this._element, this._triggerArray)
    }

    if (this._config.toggle) {
      this.toggle()
    }
  }

  toggle() {
    if (this._element.classList.contains(CLASS_NAME_SHOW)) {
      this.hide()
    } else {
      this.show()
    }
  }

  show() {
    if (this._isTransitioning || this._element.classList.contains(CLASS_NAME_SHOW)) {
      return
    }

    let actives
    let activesData

    if (this._parent) {
      actives = SelectorEngine.find(SELECTOR_ACTIVES, this._parent)
            .filter(elem => {
              if (typeof this._config.parent === 'string') {
                return elem.getAttribute('data-bs-parent') === this._config.parent
              }

              return elem.classList.contains(CLASS_NAME_COLLAPSE)
            })

      if (actives.length === 0) {
        actives = null
      }
    }

    const container = SelectorEngine.findOne(this._selector)

    if (actives) {
      const tempActiveData = actives.find(elem => container !== elem)
      activesData = tempActiveData ? NavMenu.getInstance(tempActiveData) : null

      if (activesData && activesData._isTransitioning) {
        return
      }
    }

    const startEvent = EventHandler.trigger(this._element, EVENT_SHOW)
    if (startEvent.defaultPrevented) {
      return
    }

    if (actives) {
      actives.forEach(elemActive => {
        if (container !== elemActive) {
          const children = SelectorEngine.find(SELECTOR_CHILDREN, elemActive)
                .filter(child => child.classList.contains(CLASS_NAME_ACTIVE))

          if (!children.length) {
            NavMenu.navMenuInterface(elemActive, 'hide')
          }
        }

        if (!activesData) {
          Data.set(elemActive, DATA_KEY, null)
        }
      })
    }

    const dimension = this._getDimension()

    this._element.classList.remove(CLASS_NAME_COLLAPSE)
    this._element.classList.add(CLASS_NAME_COLLAPSING)

    this._element.style[dimension] = 0

    if (this._triggerArray.length) {
      this._triggerArray.forEach(element => {
        element.classList.remove(CLASS_NAME_COLLAPSED)
        element.setAttribute('aria-expanded', true)
      })
    }

    this.setTransitioning(true)

    const complete = () => {
      this._element.classList.remove(CLASS_NAME_COLLAPSING)
      this._element.classList.add(CLASS_NAME_COLLAPSE, CLASS_NAME_SHOW)

      this._element.style[dimension] = ''

      this.setTransitioning(false)

      EventHandler.trigger(this._element, EVENT_SHOWN)
    }

    const capitalizedDimension = dimension[0].toUpperCase() + dimension.slice(1)
    const scrollSize = `scroll${capitalizedDimension}`

    this._queueCallback(complete, this._element, true)
    this._element.style[dimension] = `${this._element[scrollSize]}px`
  }

  // Static

  static navMenuInterface(element, config) {
    let data = NavMenu.getInstance(element)
    const _config = {
      ...Default,
      ...Manipulator.getDataAttributes(element),
      ...(typeof config === 'object' && config ? config : {})
    }

    if (!data && _config.toggle && typeof config === 'string' && /show|hide/.test(config)) {
      _config.toggle = false
    }

    if (!data) {
      data = new NavMenu(element, _config)
    }

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }
}

EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_DATA_TOGGLE, function (event) {
  const elem = event.target

  // preventDefault only for <a> elements (which change the URL) not inside the collapsible element
  if (elem.tagName === 'A' || (event.delegateTarget && event.delegateTarget.tagName === 'A')) {
    event.preventDefault()
  }

  if (!elem.classList.contains(CLASS_NAME_COLLAPSED)) {
    return
  }

  elem.classList.remove(CLASS_NAME_ACTIVE)

  const triggerData = Manipulator.getDataAttributes(this)
  const selector = getSelectorFromElement(this)
  const selectorElements = SelectorEngine.find(selector)

  selectorElements.forEach(element => {
    const data = NavMenu.getInstance(element)
    let config

    if (data) {
      // update parent attribute
      if (data._parent === null && typeof triggerData.parent === 'string') {
        data._config.parent = triggerData.parent
        data._parent = data._getParent()
      }

      config = 'toggle'
    } else {
      config = triggerData
    }

    NavMenu.navMenuInterface(element, config)
  })
})

EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_CHILDREN, event => {
  event.preventDefault()

  const elem = event.target
  const { parentElement } = elem
  const { parent: hostSelector } = Manipulator.getDataAttributes(parentElement)

  if (hostSelector) {
    const hostElement = SelectorEngine.findOne(hostSelector)
    const children = SelectorEngine.find(SELECTOR_CHILDREN, hostElement)
    const childActive = children.filter(child => child.classList.contains(CLASS_NAME_ACTIVE))

    if (childActive.length > 0) {
      const childActiveParent = childActive[0].parentElement

      if (childActiveParent !== parentElement) {
        NavMenu.navMenuInterface(childActive[0].parentElement, 'hide')
      }
    }

    children.forEach(element => {
      element.classList.remove(CLASS_NAME_ACTIVE)
    })

    elem.classList.add(CLASS_NAME_ACTIVE)
  }
})

/**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   * add .NavMenu to jQuery only if jQuery is present
   */

defineJQueryPlugin(NavMenu)

export default NavMenu
