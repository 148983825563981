import SelectorEngine from './dom/selector-engine'
import BaseComponent from './base-component'
import * as Mustache from 'mustache'

const NAME = 'drag-drop-uploader'
const SELECTOR = '.js-drop-area'
const INPUT_SELECTOR = '.js-drop-area-input'
const FILE_SELECTOR = '.js-drop-area-file'
const FILE_TEMPLATE_SELECTOR = '.js-drop-area-file-template'

const uploadFileMustache = Mustache.default

class DragDropUploader extends BaseComponent {
  constructor(element) {
    super(element)

    this.initDragDropUploader()
  }

  initDragDropUploader() {
    const dragDropUploader = this._element

    this.inputElement = dragDropUploader.querySelector(INPUT_SELECTOR)

    dragDropUploader.addEventListener('drop', this.handlerUploadFile, false)
    dragDropUploader.addEventListener('click', () => this.triggerInputFile(this.inputElement))
    this.inputElement.addEventListener('change', () => this.handleChangeFile())
    dragDropUploader.addEventListener('dragenter', this.preventBrowser, false)
    dragDropUploader.addEventListener('dragleave', this.preventBrowser, false)
    dragDropUploader.addEventListener('dragover', this.preventBrowser, false)
  }

  preventBrowser(e) {
    e.preventDefault()
  }

  handlerUploadFile(e) {
    e.preventDefault()
    const { files } = e.dataTransfer
    const fileInput = this.querySelector(INPUT_SELECTOR)
    fileInput.files = files
    fileInput.dispatchEvent(new CustomEvent('change', {}))
  }

  triggerInputFile(element) {
    element.click()
  }

  handleChangeFile() {
    const dragDropUploader = this._element
    const file = dragDropUploader.querySelector(FILE_SELECTOR)
    const fileTemplate = dragDropUploader.querySelector(FILE_TEMPLATE_SELECTOR).innerHTML
    file.innerHTML = uploadFileMustache.render(fileTemplate, this.getFileInfo(this.inputElement.files[0]))
  }

  getFileInfo(file) {
    const fileName = file.name
    const size = Math.round(10 * file.size / 1024) / 10
    const extension = fileName?.split('.')?.pop()

    return {
      fileName,
      size,
      extension
    }
  }

  static dragDropUploaderFormInterface(element, config) {
    const data = DragDropUploader.getOrCreateInstance(element, config)

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static get NAME() {
    return NAME
  }
}

const selectorElements = SelectorEngine.find(SELECTOR)
selectorElements.forEach(element => {
  DragDropUploader.dragDropUploaderFormInterface(element)
})

export default DragDropUploader
