import BaseComponent from './base-component'
import SelectorEngine from './dom/selector-engine'
import * as Mustache from 'mustache'

const NAME = 'vacancies'
const SELECTOR = '.js-vacancies'
const CITY_SELECTOR = '.js-cities-btn'
const POPUP_SELECTOR = '.js-vacancies-popup'
const CLOSE_POPUP_SELECTOR = '.js-vacancies-popup-close'
const BACKGROUND_POPUP_SELECTOR = '.js-vacancies-popup-background'
const POPUP_CITY_SELECTOR = '.js-vacancies-popup-city'
const FILTER_WRAP_SELECTOR = '.js-vacancies-filter-wrap'
const FILTER_SELECTED_ELEM_SELECTOR = '.js-vacancies-filter-selected-elem'
const FILTER_SELECTED_ELEM_TEMPLATE_SELECTOR = '.js-vacancies-filter-selected-elem-template'
const VACANCIES_LOACTION_TEMPLATE_SELECTOR = '.js-vacancies-location-wrap--template'
const FILTER_ELEM_SELECTOR = '.js-vacancies-filter-elem'

const POPUP_ACTIVE_CLASS = 'vacancies__popup--active'
const FILTER_WRAP_ACTIVE_CLASS = 'vacancies__filter--active'

const vacanciesMustache = Mustache.default

export default class Vacancies extends BaseComponent {
  constructor(element) {
    super(element)
    this.initVacancyPage()
  }

  initVacancyPage() {
    const vacancies = this._element
    const cityBtn = vacancies.querySelector(CITY_SELECTOR)
    const closePopupBtn = vacancies.querySelector(CLOSE_POPUP_SELECTOR)
    const backgroundPopup = vacancies.querySelector(BACKGROUND_POPUP_SELECTOR)
    const filterSelectedElem = vacancies.querySelector(FILTER_SELECTED_ELEM_SELECTOR)
    const filterSelectedElemTemplate = vacancies.querySelector(FILTER_SELECTED_ELEM_TEMPLATE_SELECTOR)
    const locationTemplate = vacancies.querySelector(VACANCIES_LOACTION_TEMPLATE_SELECTOR)
    const filterElem = vacancies.querySelectorAll(FILTER_ELEM_SELECTOR)

    this.popup = vacancies.querySelector(POPUP_SELECTOR)
    this.filterWrap = vacancies.querySelector(FILTER_WRAP_SELECTOR)

    cityBtn.addEventListener('click', () => this.showPopup())
    cityBtn.addEventListener('change', event => this.changeCity(event, locationTemplate.innerHTML))
    closePopupBtn.addEventListener('click', () => this.hidePopup())
    backgroundPopup.addEventListener('click', () => this.hidePopup())
    filterSelectedElem.addEventListener('click', () => this.changeFilterVisibility())
    filterSelectedElem.addEventListener('change', event => this.changeSelectedElem(event, filterSelectedElemTemplate))

    const cityList = vacancies.querySelectorAll(POPUP_CITY_SELECTOR)

    cityList.forEach(city => {
      city.addEventListener('click', () => this.setCurrentCity(city, cityBtn))
    })
    filterElem.forEach(elem => {
      elem.addEventListener('click', () => this.changeFilterSelectedElem(elem, filterSelectedElem))
    })
  }

  changeSelectedElem(event, templateElem) {
    event.target.innerHTML = vacanciesMustache.render(templateElem.innerHTML, { value: event.detail.newValue })
    this.changeFilterVisibility()
  }

  changeFilterSelectedElem(filterElem, filterSelectedElem) {
    filterSelectedElem.dispatchEvent(new CustomEvent('change', {
      detail: { newValue: filterElem.innerHTML }
    }))
  }

  changeFilterVisibility() {
    if (this.filterWrap.classList.contains(FILTER_WRAP_ACTIVE_CLASS)) {
      this.filterWrap.classList.remove(FILTER_WRAP_ACTIVE_CLASS)
    } else {
      this.filterWrap.classList.add(FILTER_WRAP_ACTIVE_CLASS)
    }
  }

  changeCity(event, template) {
    event.target.innerHTML = vacanciesMustache.render(template, { city: event.detail.newValue })
    this.hidePopup()
  }

  setCurrentCity(city, cityBtn) {
    cityBtn.dispatchEvent(new CustomEvent('change', {
      detail: { newValue: city.innerHTML }
    }))
  }

  showPopup() {
    this.popup.classList.add(POPUP_ACTIVE_CLASS)
  }

  hidePopup() {
    this.popup.classList.remove(POPUP_ACTIVE_CLASS)
  }

  static vacanciesInterface(element, config) {
    const data = Vacancies.getOrCreateInstance(element, config)

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static get NAME() {
    return NAME
  }
}

const selectorElements = SelectorEngine.find(SELECTOR)

selectorElements.forEach(element => {
  Vacancies.vacanciesInterface(element)
})
