import BaseComponent from './base-component'
import SelectorEngine from './dom/selector-engine'

const NAME = 'orders'
const SELECTOR = '.js-orders'
const FILTERS_SELECTOR = '.js-orders-filters'
const FILTERS_BTN_SELECTOR = '.js-orders-filters-btn'

const FILTERS_ACTIVE_CLASS = 'orders__filters--active'

export default class Orders extends BaseComponent {
  constructor(element) {
    super(element)
    this.initOrdersItems()
  }

  initOrdersItems() {
    const orders = this._element
    const ordersFilters = orders.querySelector(FILTERS_SELECTOR)
    const ordersFiltersBtn = orders.querySelector(FILTERS_BTN_SELECTOR)

    ordersFiltersBtn.addEventListener('click', () => this.changeFiltersVisibility(ordersFilters))
  }

  changeFiltersVisibility(ordersFilters) {
    if (ordersFilters.classList.contains(FILTERS_ACTIVE_CLASS)) {
      ordersFilters.classList.remove(FILTERS_ACTIVE_CLASS)
    } else {
      ordersFilters.classList.add(FILTERS_ACTIVE_CLASS)
    }
  }

  static ordersInterface(element, config) {
    const data = Orders.getOrCreateInstance(element, config)

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static get NAME() {
    return NAME
  }
}

const selectorElements = SelectorEngine.find(SELECTOR)

selectorElements.forEach(element => {
  Orders.ordersInterface(element)
})
