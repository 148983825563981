import Dropdown from './dropdown'
import SelectorEngine from './dom/selector-engine'

import { defineJQueryPlugin } from './util/index'
import EventHandler from './dom/event-handler'
import Validation from './validation'

const DATA_KEY = 'bs.select'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const CLASS_NAME_SHOW = 'show'
const RIGHT_MOUSE_BUTTON = 2
const TAB_KEY = 'Tab'

const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`
const EVENT_KEYDOWN_DATA_API = `keydown${EVENT_KEY}${DATA_API_KEY}`
const EVENT_KEYUP_DATA_API = `keyup${EVENT_KEY}${DATA_API_KEY}`

const SELECTOR_DATA_TOGGLE = '[data-bs-toggle="select"]'
const SELECTOR_MENU = '.select-menu'
const SELECT_ITEM = '.select-item'

const EVENT_CLICK = `click${EVENT_KEY}`

class Select extends Dropdown {
  constructor(element, config) {
    super(element, config)

    this.selected = null
    this.value = null
    this.validation = new Validation()
  }

  static selectInterface(element, config) {
    const data = Select.getOrCreateInstance(element, config)

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  _getMenuElement() {
    return SelectorEngine.next(this._element, SELECTOR_MENU)[0]
  }

  static clearMenus(event) {
    if (event && (event.button === RIGHT_MOUSE_BUTTON || (event.type === 'keyup' && event.key !== TAB_KEY))) {
      return
    }

    const toggles = SelectorEngine.find(SELECTOR_DATA_TOGGLE)

    for (let i = 0, len = toggles.length; i < len; i++) {
      const context = Select.getInstance(toggles[i])
      if (!context || context._config.autoClose === false) {
        continue
      }

      if (!context._element.classList.contains(CLASS_NAME_SHOW)) {
        continue
      }

      const relatedTarget = {
        relatedTarget: context._element
      }

      if (event) {
        const composedPath = event.composedPath()
        const isMenuTarget = composedPath.includes(context._menu)
        if (
          composedPath.includes(context._element) ||
          (context._config.autoClose === 'inside' && !isMenuTarget) ||
          (context._config.autoClose === 'outside' && isMenuTarget)
        ) {
          continue
        }

        // Tab navigation through the dropdown menu or events from contained inputs shouldn't close the menu
        if (context._menu.contains(event.target) && ((event.type === 'keyup' && event.key === TAB_KEY) || /input|select|option|textarea|form/i.test(event.target.tagName))) {
          continue
        }

        if (event.type === 'click') {
          relatedTarget.clickEvent = event
        }
      }

      context._completeHide(relatedTarget)
    }
  }

  onSelectItem({ selectedValue, viewValue }) {
    this.selected = selectedValue
    this._element.innerHTML = viewValue
    this.setValue(selectedValue)
  }

  setValue(selectedValue) {
    this.value = selectedValue
    this.validation.toggleErrorBlock(this._element, true)
  }

  getValue() {
    return this.value
  }

  _addEventListeners() {
    EventHandler.on(this._element, EVENT_CLICK, event => {
      event.preventDefault()
      this.toggle()
    })

    const selectItems = this._menu.querySelectorAll(SELECT_ITEM)

    selectItems.forEach(item => {
      EventHandler.on(item, EVENT_CLICK, event => {
        this.onSelectItem({
          selectedValue: event.target.dataset.value,
          viewValue: event.target.innerHTML
        })
      })
    })
  }
}

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 * add .Dropdown to jQuery only if jQuery is present
 */

EventHandler.on(document, EVENT_KEYDOWN_DATA_API, SELECTOR_DATA_TOGGLE, Select.dataApiKeydownHandler)
EventHandler.on(document, EVENT_KEYDOWN_DATA_API, SELECTOR_MENU, Select.dataApiKeydownHandler)
EventHandler.on(document, EVENT_CLICK_DATA_API, Select.clearMenus)
EventHandler.on(document, EVENT_KEYUP_DATA_API, Select.clearMenus)
EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_DATA_TOGGLE, function (event) {
  event.preventDefault()
  Select.selectInterface(this)
})

defineJQueryPlugin(Select)

export default Select
