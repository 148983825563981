import BaseComponent from './base-component'
import SelectorEngine from './dom/selector-engine'

const SELECTOR = '.js-popup-btn'
const POPUP_SELECTOR = '.js-popup-container'
const POPUP_CLOSE_SELECTOR = '.js-popup-close'
const POPUP_BACKGROUND_SELECTOR = '.js-popup-background'

const ACTIVE_CLASS = 'popup--active'

const NAME = 'popup'

export default class Popup extends BaseComponent {
  constructor(element) {
    super(element)
    this.initPopup()
  }

  initPopup() {
    const parent = this._element
    const popup = parent.querySelector(POPUP_SELECTOR)
    const popupClose = parent.querySelector(POPUP_CLOSE_SELECTOR)
    const popupBackground = parent.querySelector(POPUP_BACKGROUND_SELECTOR)

    parent.addEventListener('click', () => this.showPopup(popup))
    popupClose.addEventListener('click', { handleEvent: this.emitClosePopup, popup })
    popupBackground.addEventListener('click', { handleEvent: this.emitClosePopup, popup })
    popup.addEventListener('closePopup', { handleEvent: this.hidePopup, popup })
  }

  showPopup(popup) {
    popup.classList.add(ACTIVE_CLASS)
  }

  emitClosePopup(event) {
    event.stopPropagation()
    this.popup.dispatchEvent(new CustomEvent('closePopup', {}))
  }

  hidePopup(event) {
    event.stopPropagation()
    this.popup.classList.remove(ACTIVE_CLASS)
  }

  static popupInterface(element, config) {
    const data = Popup.getOrCreateInstance(element, config)

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static get NAME() {
    return NAME
  }
}

const selectorElements = SelectorEngine.find(SELECTOR)

selectorElements.forEach(element => {
  Popup.popupInterface(element)
})

