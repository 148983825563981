import BaseComponent from './base-component'
import SelectorEngine from './dom/selector-engine'
import EventHandler from './dom/event-handler'
import Phone from './phone'
import Select from './select'
import { defineJQueryPlugin } from './util/index'

const NAME = 'validation'
const SELECTOR = '.js-validation'
const SELECTOR_ERROR_BLOCK = '.js-validation-error'
const SELECTOR_INPUTS = 'input, textarea'
const SELECTOR_SELECT = '[data-bs-toggle="select"]'

const CLASS_HIDDEN = 'd-none'
const CLASS_PHONE_INPUT = 'js-phone'

// eslint-disable-next-line no-useless-escape
const EMAIL_PATTERN = /^([\w.\-]+)@([\w.\-]+)\.([a-z]{2,5})$/i

export default class Validation extends BaseComponent {
  constructor(element) {
    super(element)
    this.init()
  }

  init() {
    if (!this._element) {
      return
    }

    this.isDirty = false
    this._element.setAttribute('novalidate', '')

    this.inputElements = SelectorEngine.find(SELECTOR_INPUTS, this._element)
    this.selectElements = SelectorEngine.find(SELECTOR_SELECT, this._element)

    this.inputElements.forEach(element => {
      EventHandler.on(element, 'keyup', () => {
        if (this.isDirty) {
          const isValid = this.validateField(element)
          this.toggleErrorBlock(element, isValid)
        }
      })
    })

    EventHandler.on(this._element, 'submit', event => {
      if (!this.validateForm()) {
        this.isDirty = true
        event.preventDefault()
        event.stopPropagation()
      }
    }, false)
  }

  validatePhone(element) {
    const phoneInstance = Phone.getOrCreateInstance(element)
    const maskLength = phoneInstance.getFullMaskLength()
    const prefixLength = phoneInstance.getPrefixLength()
    const isRequired = element.hasAttribute('required')

    const isValid = element.value !== '' && element.value.length > prefixLength ? element.value.length === maskLength : !isRequired

    return isValid
  }

  validateField(element) {
    const { value } = element
    const isRequired = element.hasAttribute('required')
    const type = element.getAttribute('type')

    let isValid

    if (element.classList.contains(CLASS_PHONE_INPUT)) {
      isValid = this.validatePhone(element)
    } else if (type === 'email') {
      isValid = value ? value.match(EMAIL_PATTERN) : !isRequired
    } else {
      isValid = isRequired ? Boolean(value) : true
    }

    return isValid
  }

  validateSelect(element) {
    const selectInstance = Select.getOrCreateInstance(element)
    const isRequired = element.hasAttribute('data-bs-required')

    const isValid = isRequired ? selectInstance.getValue() !== null : true

    return isValid
  }

  validateForm() {
    let invalidCounter = 0

    this.inputElements.forEach(element => {
      const isValid = this.validateField(element)
      this.toggleErrorBlock(element, isValid)

      if (!isValid) {
        invalidCounter++
      }
    })

    this.selectElements.forEach(element => {
      const isValid = this.validateSelect(element)
      this.toggleErrorBlock(element, isValid)

      if (!isValid) {
        invalidCounter++
      }
    })

    return !invalidCounter
  }

  toggleErrorBlock(element, isValid) {
    const errorEl = SelectorEngine.findOne(SELECTOR_ERROR_BLOCK, element.parentElement)

    if (errorEl) {
      errorEl.classList.toggle(CLASS_HIDDEN, isValid)
    }
  }

  static validationInterface(element, config) {
    const data = Validation.getOrCreateInstance(element, config)

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static get NAME() {
    return NAME
  }
}

const selectorElements = SelectorEngine.find(SELECTOR)
selectorElements.forEach(element => {
  Validation.validationInterface(element)
})

defineJQueryPlugin(Validation)
