/**
 * --------------------------------------------------------------------------
 * Bootstrap (v5.0.1): index.umd.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

import Alert from './src/alert'
import Button from './src/button'
import Carousel from './src/carousel'
import Collapse from './src/collapse'
import Dropdown from './src/dropdown'
import Modal from './src/modal'
import NavMenu from './src/nav-menu'
import Offcanvas from './src/offcanvas'
import Popover from './src/popover'
import ScrollSpy from './src/scrollspy'
import Select from './src/select'
import Tab from './src/tab'
import Toast from './src/toast'
import Tooltip from './src/tooltip'
import Header from './src/header'
import Phone from './src/phone'
import Counter from './src/counter'
import Validation from './src/validation'
import Gallery from './src/gallery'
import DatePicker from './src/date-picker'
import DeliveryMap from './src/delivery-map'
import MobileMenu from './src/mobile-menu'
import BuyoutForm from './src/buyout-form'
import Vacancy from './src/vacancy'
import Vacancies from './src/vacancies'
import Orders from './src/orders'
import DragDropUploader from './src/drag-drop-uploader'
import Popup from './src/popup'
import CustomSelect from './src/custom-select'
import Order from './src/order'
import ListPicker from './src/list-picker'
import CitySearch from './src/city-search'
import StickyButton from './src/sticky-button'

export default {
  Alert,
  Button,
  Carousel,
  Collapse,
  Dropdown,
  Modal,
  NavMenu,
  Offcanvas,
  Popover,
  ScrollSpy,
  Select,
  Tab,
  Toast,
  Tooltip,
  Header,
  Phone,
  Counter,
  Validation,
  Gallery,
  DatePicker,
  MobileMenu,
  BuyoutForm,
  DeliveryMap,
  Vacancy,
  Vacancies,
  Orders,
  DragDropUploader,
  Popup,
  CustomSelect,
  Order,
  ListPicker,
  CitySearch,
  FixedButton: StickyButton
}
