import SelectorEngine from './dom/selector-engine'
import BaseComponent from './base-component'
import * as Mustache from 'mustache'

const NAME = 'custom-select'
const SELECTOR = '.js-custom-select'
const WRAP_SELECTOR = '.js-custom-select-wrap'
const SELECTED_ELEM_SELECTOR = '.js-custom-select-selected-elem'
const ELEM_SELECTOR = '.js-custom-select-elem'
const SELECTED_ELEM_TEMPLATE_SELECTOR = '.js-custom-select-selected-elem-template'

const ACTIVE_CLASS = 'custom-select__filter--active'
const SELECTED_ELEM_DEFAULT_CLASS = 'custom-select__selected-elem--default'

const selectMustache = Mustache.default

class CustomSelect extends BaseComponent {
  constructor(element) {
    super(element)
    this.initCustomSelect()
  }

  initCustomSelect() {
    const customSelect = this._element
    const customSelectSelectedElem = customSelect.querySelector(SELECTED_ELEM_SELECTOR)
    const filterSelectedElemTemplate = customSelect.querySelector(SELECTED_ELEM_TEMPLATE_SELECTOR)
    const customSelectElemList = customSelect.querySelectorAll(ELEM_SELECTOR)

    this.customSelectWrap = customSelect.querySelector(WRAP_SELECTOR)

    customSelectSelectedElem.addEventListener('click', () => this.changeSelectVisibility())
    customSelectSelectedElem.addEventListener('change', event => this.changeSelectedElem(event, filterSelectedElemTemplate, customSelect))
    customSelectElemList.forEach(elem => {
      elem.addEventListener('click', () => this.emitChangeSelectedElem(elem, customSelectSelectedElem))
    })
  }

  changeSelectedElem(event, templateElem, customSelect) {
    event.target.innerHTML = selectMustache.render(templateElem.innerHTML, { value: event.detail.newValue })
    this.changeSelectVisibility()
    customSelect.dispatchEvent(new CustomEvent('change', {}))
  }

  emitChangeSelectedElem(filterElem, filterSelectedElem) {
    filterSelectedElem.dispatchEvent(new CustomEvent('change', {
      detail: { newValue: filterElem.innerHTML }
    }))
    filterSelectedElem.classList.remove(SELECTED_ELEM_DEFAULT_CLASS)
  }

  changeSelectVisibility() {
    if (this.customSelectWrap.classList.contains(ACTIVE_CLASS)) {
      this.customSelectWrap.classList.remove(ACTIVE_CLASS)
    } else {
      this.customSelectWrap.classList.add(ACTIVE_CLASS)
    }
  }

  static customSelectInterface(element, config) {
    const data = CustomSelect.getOrCreateInstance(element, config)

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static get NAME() {
    return NAME
  }
}

const selectorElements = SelectorEngine.find(SELECTOR)
selectorElements.forEach(element => {
  CustomSelect.customSelectInterface(element)
})

export default CustomSelect
