import SelectorEngine from './dom/selector-engine'
import BaseComponent from './base-component'
import * as Mustache from 'mustache'

const NAME = 'buyout-form'
const SELECTOR = '.js-buyout-form'
const PRODUCT_TITLE_SELECTOR = '.js-buyout-form-product-title'
const PRODUCT_ADD_SELECTOR = '.js-buyout-form-product-add'
const PRODUCT_TEMPLATE_SELECTOR = '.js-buyout-form-product-template'
const PRODUCTS_WRAP_SELECTOR = '.js-buyout-form-products-wrap'
const PRODUCT_DELETE_SELECTOR = '.js-buyout-form-product-delete'
const PRODUCT_INPUT_SELECTOR = '.js-buyout-form-product-input'
const TAB_SELECTOR_SELECTOR = '.js-buyout-form-tab'
const TAB_SELECTOR_RIGHT_SELECTOR = '.js-buyout-form-right-tab'
const FORM_ELEM_SELECTOR = '.js-buyout-form-elem'
const FORM_SELECTOR = '.js-buyout-form-form'
const FORM_UPLOAD_SELECTOR = '.js-buyout-form-upload'

const ACTIVE_TAB_SELECTOR_CLASS = 'js-buyout-form-tab--active'

const ACTIVE_TAB_CLASS = 'buyout-form__left-elem--active'
const ACTIVE_ELEM_CLASS = 'buyout-form__right-elem--active'
const ACTIVE_PRODUCT_INPUT_CLASS = 'buyout-form__product-input--active'
const ACTIVE_PRODUCT_TITLE_CLASS = 'buyout-form__product-title--active'
const ACTIVE_TAB_RIGHT_SELECTOR_CLASS = 'buyout-form__right-tab--active'
const FORM_TABLE_MODIFICATOR = 'buyout-form__form--table'
const PRODUCTS_WRAP_MODIFICATOR = 'buyout-form__products-wrap--delete'

const productMustache = Mustache.default

class BuyoutForm extends BaseComponent {
  constructor(element) {
    super(element)
    this.initBuyOutForm()
  }

  initBuyOutForm() {
    const parent = this._element
    const tabs = parent.querySelectorAll(TAB_SELECTOR_SELECTOR)
    const elem = parent.querySelectorAll(FORM_ELEM_SELECTOR)
    const formTabs = parent.querySelectorAll(TAB_SELECTOR_RIGHT_SELECTOR)
    const form = parent.querySelector(FORM_SELECTOR)
    const uploadInputList = parent.querySelectorAll(FORM_UPLOAD_SELECTOR)

    tabs.forEach((tab, index) => {
      tab.dataset.index = index + 1
      tab.addEventListener('click', () => this.initTab(tab))
    })

    uploadInputList.forEach(uploadInput => {
      uploadInput.addEventListener('change', () => this.showUploadedFile(uploadInput))
    })

    elem.forEach((elem, index) => {
      elem.dataset.index = index + 1
      elem.classList.add(`js-buyout-form-elem-${elem.dataset.index}`)
    })

    this.initFormTabs(form, formTabs)
    this.initAddProduct()
  }

  initFormTabs(form, formTabs) {
    formTabs.forEach(formTab => {
      formTab.addEventListener('click', () => {
        if (!formTab.classList.contains(ACTIVE_TAB_RIGHT_SELECTOR_CLASS)) {
          formTabs.forEach(currentTub => {
            currentTub.classList.remove(ACTIVE_TAB_RIGHT_SELECTOR_CLASS)
          })
          formTab.classList.add(ACTIVE_TAB_RIGHT_SELECTOR_CLASS)
          if (form.classList.contains(FORM_TABLE_MODIFICATOR)) {
            form.classList.remove(FORM_TABLE_MODIFICATOR)
          } else {
            form.classList.add(FORM_TABLE_MODIFICATOR)
          }
        }
      })
    })
  }

  showUploadedFile(uploadInput) {
    const container = uploadInput.parentNode.querySelector('.js-buyout-form-uploaded-files')
    const template = document.querySelector('.js-buyout-form-uploaded-template').innerHTML

    const params = this.getFileInfo(uploadInput.files[0])

    container.innerHTML = this.renderFileResult(template, params)
  }

  getFileInfo(file) {
    const fileName = file.name
    const size = Math.round(10 * file.size / 1024) / 10
    const extension = fileName?.split('.')?.pop()

    return {
      fileName,
      size,
      extension
    }
  }

  renderFileResult(template, params) {
    return productMustache.render(template, params)
  }

  renderProduct(container, templateElem) {
    const template = templateElem.innerHTML
    const { count } = container.dataset
    const index = count || 0
    container.dataset.count = Number(index) + 1
    const rendered = productMustache.render(template, {
      index: container.dataset.count
    })
    const wrap = document.createElement('div')

    wrap.innerHTML = rendered
    const visibilitySetter = wrap.querySelector(PRODUCT_TITLE_SELECTOR)
    const deleteProduct = wrap.querySelector(PRODUCT_DELETE_SELECTOR)

    visibilitySetter.addEventListener('click', () => {
      const productContainer = wrap.querySelector(PRODUCT_INPUT_SELECTOR)

      if (productContainer.classList.contains(ACTIVE_PRODUCT_INPUT_CLASS)) {
        productContainer.classList.remove(ACTIVE_PRODUCT_INPUT_CLASS)
        visibilitySetter.classList.remove(ACTIVE_PRODUCT_TITLE_CLASS)
      } else {
        productContainer.classList.add(ACTIVE_PRODUCT_INPUT_CLASS)
        visibilitySetter.classList.add(ACTIVE_PRODUCT_TITLE_CLASS)
      }
    })
    deleteProduct.addEventListener('click', () => {
      this.deleteProductWrap(wrap, container, templateElem)
    })
    this.changeSecondBlockClassList(container)
    container.appendChild(wrap)
  }

  deleteProductWrap(wrap, container, templateElem) {
    wrap.remove()
    container.dataset.count = Number(container.dataset.count) - 1
    const titleList = container.querySelectorAll(PRODUCT_TITLE_SELECTOR)

    this.changeSecondBlockClassList(container)

    titleList.forEach((title, index) => {
      const titleTemplate = templateElem.querySelector(PRODUCT_TITLE_SELECTOR).innerHTML

      this.renderTitle(title, titleTemplate, index + 1)
    })
  }

  changeSecondBlockClassList(container) {
    if (container.dataset.count > 1) {
      container.classList.add(PRODUCTS_WRAP_MODIFICATOR)
    } else {
      container.classList.remove(PRODUCTS_WRAP_MODIFICATOR)
    }
  }

  renderTitle(title, titleTemplate, index) {
    const rendered = productMustache.render(titleTemplate, {
      index
    })

    title.innerHTML = rendered
  }

  initTab(tab) {
    const currentTab = this._element.getElementsByClassName(ACTIVE_TAB_SELECTOR_CLASS)[0]

    const selectedIndex = tab.dataset.index
    const currentIndex = currentTab?.dataset.index

    const selectedElem = this._element.querySelector(`.js-buyout-form-elem-${selectedIndex}`)
    const currentElem = this._element.querySelector(`.js-buyout-form-elem-${currentIndex}`)

    if (currentTab) {
      currentTab.classList.remove(ACTIVE_TAB_CLASS)
      currentTab.classList.remove(ACTIVE_TAB_SELECTOR_CLASS)
      currentElem.classList.remove(ACTIVE_ELEM_CLASS)
    }

    tab.classList.add(ACTIVE_TAB_CLASS)
    tab.classList.add(ACTIVE_TAB_SELECTOR_CLASS)
    selectedElem.classList.add(ACTIVE_ELEM_CLASS)
  }

  initAddProduct() {
    const parentElem = this._element
    const addProductBtn = parentElem.querySelector(PRODUCT_ADD_SELECTOR)
    const template = parentElem.querySelector(PRODUCT_TEMPLATE_SELECTOR)
    const productWrap = parentElem.querySelector(PRODUCTS_WRAP_SELECTOR)

    this.renderProduct(productWrap, template)
    addProductBtn.addEventListener('click', () => this.renderProduct(productWrap, template))
  }

  static buyoutFormInterface(element, config) {
    const data = BuyoutForm.getOrCreateInstance(element, config)

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static get NAME() {
    return NAME
  }
}

const selectorElements = SelectorEngine.find(SELECTOR)
selectorElements.forEach(element => {
  BuyoutForm.buyoutFormInterface(element)
})

export default BuyoutForm
