import BaseComponent from './base-component'
import { defineJQueryPlugin } from './util/index'

const NAME = 'fixed-button'

export default class StickyButton extends BaseComponent {
  constructor(element) {
    super(element)
    this.init()
  }

  init() {
    window.addEventListener('scroll', () => {
      if (window.XMLHttpRequest) {
        if (document.documentElement.scrollTop > 2320 || window.pageYOffset > 2320) {
          document.getElementById('form_submit_button_mobile').style.position = 'fixed'
          document.getElementById('form_submit_button_mobile').style.top = '0'
        } else if (document.documentElement.scrollTop < 2320 || window.pageYOffset < 2320) {
          document.getElementById('form_submit_button_mobile').style.top = ''
        }
      }
    })
  }

  static get NAME() {
    return NAME
  }
}

defineJQueryPlugin(StickyButton)
