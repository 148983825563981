import BaseComponent from './base-component'
import SelectorEngine from './dom/selector-engine'
import { defineJQueryPlugin } from './util/index'
import * as Mustache from 'mustache'

const SELECTOR = '.js-city-search'
const INPUT_SELECTOR = '.js-city-search-input'
const LIST_SELECTOR = '.js-city-search-list'
const NAME = 'city-search'

const citiesTemplate =
    '  {{#cities}}\n' +
    '     <div class="city-search__city {{cityClass}}">\n' +
    '       <div class="city-search__city-letter city-search__city-letter--{{mod}}">\n' +
    '         {{letter}}\n' +
    '       </div>\n' +
    '       {{city}}\n' +
    '     </div>\n' +
    '  {{/cities}}\n'

const citySearchMustache = Mustache.default

export default class CitySearch extends BaseComponent {
  constructor(element) {
    super(element)
    this.initCitySearch()
  }

  initCitySearch() {
    const citiesContainer = this._element
    this.citiesList = citiesContainer.querySelector(LIST_SELECTOR)
    this.input = citiesContainer.querySelector(INPUT_SELECTOR)
    this.citiesListTemplate = citiesTemplate

    const cityClass = this.citiesList.dataset.child

    this.cities = [].map.call(citiesContainer.querySelectorAll('li'), elem => {
      const city = elem.textContent
      const cityObj = { city, letter: city[0], cityClass }
      return cityObj
    })

    this.input.addEventListener('input', () => this.handleInput())

    this.showFilteredCities(this.prepareCities(this.cities))
  }

  handleInput() {
    const searchLine = this.input.value.trim()
    const filteredCities = this.cities.filter(cityObj => cityObj.city.indexOf(searchLine) === 0)
    this.showFilteredCities(this.prepareCities(filteredCities))
  }

  prepareCities(cities) {
    cities.forEach((elem, index) => {
      if (index === 0) {
        elem.mod = 'first'
      } else {
        elem.mod = elem.letter === cities[index - 1].letter ? 'hide' : 'first'
      }
    })
    return { cities }
  }

  showFilteredCities(filteredCities) {
    this.citiesList.innerHTML = citySearchMustache.render(this.citiesListTemplate, filteredCities)
  }

  getTemplate() {
    return '<div class="js-city-search-template city-search__template">\n' +
        '              {{#cities}}\n' +
        '              <div class="city-search__city">\n' +
        '                <div class="city-search__city-letter city-search__city-letter--{{mod}}">\n' +
        '                  {{letter}}\n' +
        '                </div>\n' +
        '                {{city}}\n' +
        '              </div>\n' +
        '              {{/cities}}\n' +
        '            </div>'
  }

  static CitySearchInterface(element, config) {
    const data = CitySearch.getOrCreateInstance(element, config)

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static get NAME() {
    return NAME
  }
}

const selectorElements = SelectorEngine.find(SELECTOR)

selectorElements.forEach(element => {
  CitySearch.CitySearchInterface(element)
})

defineJQueryPlugin(CitySearch)
