import BaseComponent from './base-component'
import EventHandler from './dom/event-handler'
import { Breakpoints, defineJQueryPlugin } from './util/index'

const NAME = 'header'
const CLASS_CONTROL_FOCUSED = 'header-main--search-focused'

export default class Header extends BaseComponent {
  constructor(element) {
    super(element)
    this.init()
  }

  init() {
    if (!this._element) {
      return
    }

    const searchButEl = this._element.querySelector('.header-main__search-but')
    const searchFormEl = this._element.querySelector('.header-main__search form')
    const headerMainEl = this._element.querySelector('.header-main')
    const headerSearchCancelEl = this._element.querySelector('.js-header-search-cancel')
    const catalogButEl = this._element.querySelector('.header-main__catalog-btn')
    const catalogModalEl = document.getElementById('modalCatalog')

    if (searchFormEl) {
      if (searchButEl) {
        const input = searchFormEl.querySelector('input')

        EventHandler.on(searchButEl, 'click', () => {
          headerMainEl.classList.add(CLASS_CONTROL_FOCUSED)
          input.focus()
        })
      }

      if (headerSearchCancelEl && headerMainEl) {
        EventHandler.on(window, 'resize', () => {
          const width = window.innerWidth
          const isMobile = width < Breakpoints.lg

          if (headerMainEl.classList.contains(CLASS_CONTROL_FOCUSED)) {
            headerMainEl.classList.toggle(CLASS_CONTROL_FOCUSED, isMobile)
          }
        })

        EventHandler.on(headerSearchCancelEl, 'click', () => {
          headerMainEl.classList.remove(CLASS_CONTROL_FOCUSED)
        })
      }
    }

    if (catalogModalEl) {
      catalogModalEl.addEventListener('show.bs.modal', () => {
        catalogButEl.classList.add('active')
      })

      catalogModalEl.addEventListener('hide.bs.modal', () => {
        catalogButEl.classList.remove('active')
      })
    }
  }

  static selectInterface(element, config) {
    const data = Header.getOrCreateInstance(element, config)

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static get NAME() {
    return NAME
  }
}

const headerElement = document.querySelector('header')
Header.selectInterface(headerElement)

defineJQueryPlugin(Header)
