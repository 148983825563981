import BaseComponent from './base-component'
import SelectorEngine from './dom/selector-engine'
import 'jquery'
import { getjQuery, defineJQueryPlugin } from './util/index'
import 'air-datepicker/dist/js/datepicker'

const SELECTOR = '.js-date-picker'
const NAME = 'custom-datepicker'

const $DatePicker = getjQuery()

const week = [
  'воскресенье',
  'понедельник',
  'вторник',
  'среда',
  'четверг',
  'пятница',
  'сцббота'
]

export default class DatePicker extends BaseComponent {
  constructor(element) {
    super(element)
    this.initDatePicker()
  }

  initDatePicker() {
    $DatePicker(this._element).each((_, element) => {
      const picker = $DatePicker(element).datepicker({
        range: this._element.dataset.multipicker,
        multipleDatesSeparator: ' \u2013 ',
        showOtherMonths: false,
        classes: 'custom-datepicker js-custom-datepicker',
        maxDate: new Date(),
        clearButton: true,
        language: {
          clear: 'Сбросить'
        },
        onSelect(fd, date, picker) {
          if (!this.range) {
            picker.el.value = `${picker.el.value}, ${week[date.getDay()]}`
          }
        }
      }).data('datepicker')

      if (picker) {
        this.createButtons(picker)
        this.createAcceptBtn(picker)
      }
    })
  }

  acceptDate(datePicker) {
    if (datePicker.selectedDates?.length) {
      const acceptEvent = new Event('accept', { bubbles: true })

      this._element.dispatchEvent(acceptEvent)
      datePicker.hide()
    }
  }

  createButtons(picker) {
    const pickerEl = picker.$datepicker[0]
    const datePickerControlsWrap = document.createElement('div')
    const datePickerFirstControlWrap = document.createElement('div')
    const datePickerSecondControlWrap = document.createElement('div')
    const datePickerThirdControlWrap = document.createElement('div')
    const datePickerFirstControl = document.createElement('div')
    const datePickerSecondControl = document.createElement('div')
    const datePickerThirdControl = document.createElement('div')

    datePickerControlsWrap.classList.add('js-custom-datepicker-controls')
    datePickerControlsWrap.classList.add('datepicker__controls-wrap')
    datePickerFirstControlWrap.classList.add('datepicker__control-wrap')
    datePickerSecondControlWrap.classList.add('datepicker__control-wrap')
    datePickerThirdControlWrap.classList.add('datepicker__control-wrap')
    datePickerFirstControl.classList.add('datepicker__control')
    datePickerSecondControl.classList.add('datepicker__control')
    datePickerThirdControl.classList.add('datepicker__control')
    datePickerFirstControl.innerHTML = 'Год'
    datePickerSecondControl.innerHTML = 'Месяц'
    datePickerThirdControl.innerHTML = 'День'
    datePickerFirstControlWrap.appendChild(datePickerFirstControl)
    datePickerSecondControlWrap.appendChild(datePickerSecondControl)
    datePickerThirdControlWrap.appendChild(datePickerThirdControl)

    datePickerFirstControlWrap.addEventListener('click', () => {
      picker.view = 'years'
    })
    datePickerSecondControlWrap.addEventListener('click', () => {
      picker.view = 'months'
    })
    datePickerThirdControlWrap.addEventListener('click', () => {
      picker.view = 'days'
    })

    datePickerControlsWrap.appendChild(datePickerFirstControlWrap)
    datePickerControlsWrap.appendChild(datePickerSecondControlWrap)
    datePickerControlsWrap.appendChild(datePickerThirdControlWrap)
    pickerEl.insertBefore(datePickerControlsWrap, pickerEl.firstChild)
  }

  createAcceptBtn(picker) {
    const pickerEl = picker.$datepicker[0]
    const datePickerButtons = SelectorEngine.findOne('.datepicker--buttons', pickerEl)
    const acceptDatePicker = document.createElement('span')

    acceptDatePicker.innerHTML = 'Применить'
    acceptDatePicker.classList.add('datepicker__accept-btn')
    acceptDatePicker.addEventListener('click', () => this.acceptDate(picker))
    acceptDatePicker.classList.add('datepicker__accept-btn')
    datePickerButtons.appendChild(acceptDatePicker)
  }

  static DatePickerInterface(element, config) {
    const data = DatePicker.getOrCreateInstance(element, config)

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static get NAME() {
    return NAME
  }
}

const selectorElements = SelectorEngine.find(SELECTOR)

selectorElements.forEach(element => {
  DatePicker.DatePickerInterface(element)
})

defineJQueryPlugin(DatePicker)
